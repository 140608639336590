// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "z_sP d_gR d_cv d_dt";
export var quoteParagraphCenter = "z_gS d_gS d_cv d_dv";
export var quoteParagraphRight = "z_gT d_gT d_cv d_dw";
export var quoteRowLeft = "z_sQ d_bF";
export var quoteRowCenter = "z_sR d_bC";
export var quoteRowRight = "z_sS d_bG";
export var quoteWrapper = "z_gP d_gP d_v d_cC d_by d_bN d_bC";
export var quoteContentWrapper = "z_gQ d_gQ";
export var quoteExceptionSmall = "z_sT F_sT";
export var quoteExceptionNormal = "z_sV F_sV";
export var quoteExceptionLarge = "z_sW F_sW";
export var quoteAuthorExceptionSmall = "z_sX F_sX";
export var quoteAuthorExceptionNormal = "z_sY F_sY";
export var quoteAuthorExceptionLarge = "z_sZ F_sZ";
// extracted by mini-css-extract-plugin
export var socialContainer = "B_s0";
export var instagramLink = "B_s1";
export var socialWrapperLeft = "B_nm d_dB d_Y d_by d_bH d_bn d_bF";
export var socialContentContainer = "B_s2";
export var socialContentContainerFull = "B_s3";
export var instagramContainer = "B_s4";
export var twitterContainer = "B_s5";
export var facebookContainer = "B_s6";
export var socialErrorContainer = "B_s7";
export var facebookContainerWide = "B_s8";
export var twitterContainerWide = "B_s9";
export var socialParagraphCenter = "B_tb";
export var instaWrapper = "B_tc";
export var SubtitleSmall = "B_pS F_pS F_tj F_tv";
export var SubtitleNormal = "B_pT F_pT F_tj F_tw";
export var SubtitleLarge = "B_pV F_pV F_tj F_tx";
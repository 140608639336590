// extracted by mini-css-extract-plugin
export var customText = "r_rq d_dt d_cr d_cf";
export var videoIframeStyle = "r_pv d_d4 d_v d_G d_bx d_b0 d_Q";
export var customImageWrapper = "r_rr d_cr d_cf d_Y";
export var customRow = "r_pQ d_bC d_bd";
export var quoteWrapper = "r_gP d_bB d_bN d_cr d_cf";
export var quoteBar = "r_pz d_G";
export var masonryImageWrapper = "r_pH";
export var title = "r_rs";
export var Title3Small = "r_rt F_rt F_tj F_tr";
export var Title3Normal = "r_rv F_rv F_tj F_ts";
export var Title3Large = "r_rw F_rw F_tj F_tt";
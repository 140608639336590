// extracted by mini-css-extract-plugin
export var articleWrapper = "l_pc";
export var articleText = "l_pd d_dt d_cr";
export var header = "l_pf d_Y d_v";
export var headerImageWrapper = "l_pg d_bc d_Z d_7 d_6 d_3 d_8 d_bj d_bQ";
export var headerGradient = "l_ph d_bc d_Z d_7 d_6 d_3 d_8";
export var headerGradientOverlay = "l_pj d_bc d_Z d_7 d_6 d_3 d_8";
export var headerContentWrapper = "l_pk d_by d_bN d_bC d_bH d_bk d_Y";
export var headerContentWrapperAlt = "l_pl d_by d_bN d_bC d_bH d_bk d_Y";
export var contentWrapper = "l_pm d_v";
export var dateTag = "l_pn d_cd d_cq d_dp d_v";
export var icon1 = "l_pp d_cN";
export var icon2 = "l_pq d_cN d_cH";
export var tagBtn = "l_pr d_b0 d_cC d_dn d_dz";
export var headerText = "l_ps d_v d_cd d_cr d_bV";
export var center = "l_pt d_dv";
export var videoIframeStyle = "l_pv d_d4 d_v d_G d_bx d_b0 d_Q";
export var articleImageWrapper = "l_pw d_cr d_Y";
export var articleImageWrapperIcon = "l_px d_cr d_Y";
export var articleRow = "l_py d_bC";
export var quoteWrapper = "l_gP d_bB d_bN d_cr";
export var quoteBar = "l_pz d_G";
export var quoteText = "l_pB";
export var authorBox = "l_pC d_v";
export var authorRow = "l_pD d_bC d_bV d_cx";
export var separator = "l_pF d_v";
export var line = "l_fj d_v d_fj d_cX d_cx";
export var authorImage = "l_ln d_b6 d_Y d_v d_cs";
export var authorText = "l_pG d_cg d_cs";
export var masonryImageWrapper = "l_pH";
export var bottomSeparator = "l_pJ d_v d_cx";
export var linksWrapper = "l_pK d_cg d_dt";
export var comments = "l_pL d_bC d_cx";
export var sharing = "l_pM d_by d_bC d_bH";
export var shareText = "l_pN d_v d_dv";
export var icon = "l_pP";
export var customRow = "l_pQ d_bC d_Y";
export var text = "l_pR";
export var SubtitleSmall = "l_pS";
export var SubtitleNormal = "l_pT";
export var SubtitleLarge = "l_pV";
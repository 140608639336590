// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "w_sm d_gt d_cr";
export var heroHeaderCenter = "w_gv d_gv d_cr d_dv";
export var heroHeaderRight = "w_gw d_gw d_cr d_dw";
export var heroParagraphLeft = "w_sn d_gq d_cv";
export var heroParagraphCenter = "w_gr d_gr d_cv d_dv";
export var heroParagraphRight = "w_gs d_gs d_cv d_dw";
export var heroBtnWrapperLeft = "w_sp d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "w_sq d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "w_sr d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "w_ss d_gp d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "w_st d_gn d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "w_sv F_sv";
export var heroExceptionNormal = "w_sw F_sw";
export var heroExceptionLarge = "w_sx F_sx";
export var Title1Small = "w_sy F_sy F_tj F_tk";
export var Title1Normal = "w_sz F_sz F_tj F_tl";
export var Title1Large = "w_sB F_sB F_tj F_tm";
export var BodySmall = "w_rD F_rD F_tj F_tC";
export var BodyNormal = "w_rF F_rF F_tj F_tD";
export var BodyLarge = "w_rG F_rG F_tj F_tF";
// extracted by mini-css-extract-plugin
export var carouselContainer = "K_n3 d_v d_G d_bd d_Y";
export var carouselContainerCards = "K_v1 K_n3 d_v d_G d_bd d_Y";
export var carouselContainerSides = "K_v2 d_v d_G d_Y";
export var prevCarouselItem = "K_v3 d_v d_G d_Z d_j";
export var prevCarouselItemL = "K_v4 K_v3 d_v d_G d_Z d_j";
export var moveInFromLeft = "K_v5";
export var prevCarouselItemR = "K_v6 K_v3 d_v d_G d_Z d_j";
export var moveInFromRight = "K_v7";
export var selectedCarouselItem = "K_v8 d_v d_G d_Y d_bd";
export var selectedCarouselItemL = "K_v9 K_v8 d_v d_G d_Y d_bd";
export var selectedCarouselItemR = "K_wb K_v8 d_v d_G d_Y d_bd";
export var nextCarouselItem = "K_wc d_v d_G d_Z d_j";
export var nextCarouselItemL = "K_wd K_wc d_v d_G d_Z d_j";
export var nextCarouselItemR = "K_wf K_wc d_v d_G d_Z d_j";
export var arrowContainer = "K_wg d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainer = "K_wh K_wg d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainerHidden = "K_wj K_wh K_wg d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var nextArrowContainer = "K_wk K_wg d_G d_Z d_dz d_bk d_3 d_b0";
export var carouselContainerProducts = "K_wl";
export var nextArrowContainerHidden = "K_wm K_wk K_wg d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var arrow = "K_kG d_Z";
export var prevArrow = "K_wn K_kG d_Z";
export var nextArrow = "K_wp K_kG d_Z";
export var carouselIndicatorContainer = "K_wq d_v d_dv d_bk d_by d_bC d_bN d_bH d_Z";
export var btnsContainer = "K_wr d_v d_by d_bD";
export var carouselText = "K_ws d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var carouselTextInactive = "K_wt K_ws d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var indicator = "K_wv d_b6";
export var carouselIndicator = "K_ww K_wv d_b6";
export var carouselIndicatorSelected = "K_wx K_wv d_b6";
export var arrowsContainerTopRight = "K_wy d_Z d_bk d_bB";
export var arrowsContainerBottomLeft = "K_wz d_Z d_bk d_bB";
export var arrowsContainerSides = "K_wB d_Z d_bk d_bB";
export var smallArrowsBase = "K_wC d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainer = "K_wD K_wC d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrev = "K_wF K_wD K_wC d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerSmall = "K_wG K_wC d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrevSmall = "K_wH K_wG K_wC d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var icon = "K_pP";
export var iconSmall = "K_wJ";
export var multipleWrapper = "K_wK d_bB d_bD d_bd";
export var multipleImage = "K_wL d_bB";
export var sidesPrevContainer = "K_wM K_wD K_wC d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";
export var sidesNextContainer = "K_wN K_wD K_wC d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";
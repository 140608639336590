// extracted by mini-css-extract-plugin
export var noMargin = "k_n1 d_cb";
export var carouselCol = "k_n2 d_cC";
export var carouselWrapper = "k_k6 d_k6 d_by d_bC d_bN d_v d_G";
export var carouselImageWrapper = "k_k7 d_k7 d_G d_v";
export var carouselContainer = "k_n3 d_cC";
export var carouselContainerFull = "k_n4 d_dS";
export var carouselImg = "k_mN d_k8 d_v d_G d_bQ";
export var imageTextWrapperLeft = "k_k1 d_k1 d_k0 d_Z d_bS";
export var imageTextWrapperCenter = "k_k2 d_k2 d_k0 d_Z d_bS";
export var imageTextWrapperRight = "k_k3 d_k3 d_k0 d_Z d_bS";
export var imageTextWrapperHidden = "k_kZ d_kZ";
export var imageAltSmall = "k_n5 d_k4";
export var imageAltNormal = "k_n6 d_k4";
export var imageAltLarge = "k_n7 d_k4";
export var imageTextSmall = "k_n8 d_k5";
export var imageTextNormal = "k_n9 d_k5";
export var imageTextLarge = "k_pb d_k5";
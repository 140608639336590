// extracted by mini-css-extract-plugin
export var alignDiscLeft = "t_rH d_fn d_bF d_dt";
export var alignLeft = "t_pW d_fn d_bF d_dt";
export var alignDiscCenter = "t_rJ d_fp d_bC d_dv";
export var alignCenter = "t_bN d_fp d_bC d_dv";
export var alignDiscRight = "t_rK d_fq d_bG d_dw";
export var alignRight = "t_pX d_fq d_bG d_dw";
export var footerContainer = "t_rL d_dV d_bV";
export var footerContainerFull = "t_rM d_dS d_bV";
export var footerHeader = "t_kd d_kd";
export var footerTextWrapper = "t_rN d_v";
export var footerDisclaimerWrapper = "t_kl d_kl d_cj";
export var badgeWrapper = "t_rP d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "t_rQ undefined";
export var footerDisclaimerLeft = "t_rR undefined";
export var verticalTop = "t_rS d_by d_bH d_bM d_bK";
export var firstWide = "t_rT";
export var disclaimer = "t_rV d_by d_bH";
export var socialDisclaimer = "t_rW";
export var left = "t_rX";
export var wide = "t_rY d_cx";
export var right = "t_rZ d_bJ";
export var line = "t_fj d_fk d_ct";
export var badgeDisclaimer = "t_r0 d_bB d_bN d_bH";
export var badgeContainer = "t_r1 d_by d_bG d_bN";
export var badge = "t_r2";
export var padding = "t_r3 d_c6";
export var end = "t_r4 d_bG";
export var linkWrapper = "t_r5 d_dz";
export var link = "t_mB d_dz";
export var colWrapper = "t_r6 d_cw";
export var consent = "t_f d_f d_bB d_bN";
export var media = "t_r7 d_bw d_dx";
export var itemRight = "t_r8";
export var itemLeft = "t_r9";
export var itemCenter = "t_sb";
export var exceptionWeight = "t_sc F_tL";
// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "J_fP d_fP d_by d_bG d_bH";
export var navbarDividedRight = "J_fQ d_fQ d_by d_bH";
export var menuDesign6 = "J_vx d_fL d_by d_dv d_bN d_bH";
export var menuDesign7 = "J_vy d_fL d_by d_dv d_bN d_bH";
export var menuRight = "J_vz d_fL d_by d_dv d_bN d_bH";
export var menuLeft = "J_vB d_fL d_by d_dv d_bN d_bH";
export var menuCenter = "J_vC d_fM d_fL d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "J_qj d_fM d_fL d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "J_qt d_fN d_fL d_by d_dv d_bN d_bH";
export var isBurger = "J_vD";
export var navbarItem = "J_qk d_bw";
export var navbarLogoItemWrapper = "J_fX d_fX d_bB d_bN";
export var burgerToggleVisibleOpen = "J_vF d_gc d_bx d_Y d_br";
export var burgerToggleVisible = "J_vG d_gc d_bx d_Y d_br";
export var burgerToggle = "J_vH d_gc d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "J_vJ d_gc d_bx d_Y d_br";
export var burgerInput = "J_vK d_f1 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "J_vL d_f2 d_v d_G";
export var burgerLine = "J_vM d_f0";
export var burgerMenuDesign6 = "J_vN d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "J_vP d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "J_vQ d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "J_vR d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "J_vS d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "J_vT d_f8 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "J_vV d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var secondary = "J_vW d_bB d_bN";
export var staticBurger = "J_vX";
export var menu = "J_vY";
export var navbarDividedLogo = "J_vZ";
export var nav = "J_v0";
// extracted by mini-css-extract-plugin
export var lbContainerOuter = "L_wP";
export var lbContainerInner = "L_wQ";
export var movingForwards = "L_wR";
export var movingForwardsOther = "L_wS";
export var movingBackwards = "L_wT";
export var movingBackwardsOther = "L_wV";
export var lbImage = "L_wW";
export var lbOtherImage = "L_wX";
export var prevButton = "L_wY";
export var nextButton = "L_wZ";
export var closing = "L_w0";
export var appear = "L_w1";
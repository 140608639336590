// extracted by mini-css-extract-plugin
export var alignLeft = "s_pW d_bF";
export var alignCenter = "s_bN d_bC";
export var alignRight = "s_pX d_bG";
export var embedInnerWrapperDesign1 = "s_rx d_bL";
export var embed = "s_ry d_b0";
export var titleMargin = "s_rz d_cv d_dv";
export var subtitleMargin = "s_rB d_cr d_dv";
export var paragraphMargin = "s_rC d_cv d_dv";
export var SubtitleSmall = "s_pS F_pS F_tj F_tv";
export var SubtitleNormal = "s_pT F_pT F_tj F_tw";
export var SubtitleLarge = "s_pV F_pV F_tj F_tx";
export var BodySmall = "s_rD F_rD F_tj F_tC";
export var BodyNormal = "s_rF F_rF F_tj F_tD";
export var BodyLarge = "s_rG F_rG F_tj F_tF";
// extracted by mini-css-extract-plugin
export var noPadding = "H_fh d_fh";
export var videoFrameWrapper = "H_vr d_kS d_b4";
export var videoIframeStyle = "H_pv d_d4 d_v d_G d_bx d_b0 d_Q";
export var videoLayoutRight = "H_vs d_kR d_bJ";
export var videoInnerWrapper = "H_vt d_fb d_d9 d_N d_Y d_by d_bC d_bN d_b4";
export var videoText = "H_vv d_dv d_v";
export var videoInnerInnerWrapper = "H_vw d_by d_bC d_bH";
export var btnWrapper = "H_d1 d_v d_by d_bC d_ch";
export var btn = "H_mz d_b0 d_by d_bC d_bN d_cG d_b5 d_dz d_m F_t0 F_tN";
export var icon = "H_pP d_cB";